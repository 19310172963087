import jquery from "jquery";
window.jQuery = window.$ = jquery;

// Bootstrap Framework
import "./scss/index.scss";
import "@popperjs/core";
import "bootstrap";

import "slick-carousel";
import hcSticky from "hc-sticky";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import lightGallery from "lightgallery";

//#region bootstrap navbar + hamburger + body scroll lock
var navbarMain = document.getElementById("navbarMain");
navbarMain.addEventListener("show.bs.collapse", function () {
  $(".navbar-toggler .hamburger").addClass("is-active");
  let scrollableNav = document.querySelector("#navbarMain");
  /* disableBodyScroll(scrollableNav); */
});
navbarMain.addEventListener("hidden.bs.collapse", function () {
  $(".navbar-toggler .hamburger").removeClass("is-active");
  let scrollableNav = document.querySelector("#navbarMain");
  /* enableBodyScroll(scrollableNav); */
});
//#endregion

//#region stickybar
document.addEventListener("DOMContentLoaded", function () {
  new hcSticky(".stickybar", {
    stickTo: "body",
    stickyClass: "stickybar--sticked",
  });
});
//#endregion

//#region sticky sidebar
document.addEventListener("DOMContentLoaded", function () {
  new hcSticky(".sidebar-product-container", {
    stickTo: "#sidebar",
    stickyClass: "sidebar-product-container--sticked",
    top: 175,
  });
});
//#endregion



//#region close menu search
$(".close-search").on("click", function (e) {
  $(".collapse-search").removeClass("show");
  $("search-trigger").addClass("collapsed");
});
//#endregion

//#region offcavas mobile
$("[data-trigger]").on("click", function () {
  var trigger_id = $(this).attr("data-trigger");
  $(trigger_id).toggleClass("show");
  $("body").toggleClass("offcanvas-active");
});
$(".close-icon").on("click", function (e) {
  $(".navbar-collapse").removeClass("show");
  $("body").removeClass("offcanvas-active");
});
//#endregion

//#region sliders
$(function () {
  //#region main slider homepage
  $(".sliderhome").slick({
    infinite: true,
    slidesToShow: 1,
    dots: true,
    arrows: false,
    speed: 600,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: false,
          adaptiveHeight: false,
          autoplay: true,
          autoplaySpeed: 3000,
        },
      },
    ],
  });
  //#endregion
  //#region product slider
  $(".slider-product").slick({
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 3,
    dots: true,
    arrows: false,
    centerPadding: "30px",
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
    ],
  });
  //#endregion

});
//#endregion

//#region input number
$(document).on('click', '.number-spinner button', function () {
  var btn = $(this),
    oldValue = btn.closest('.number-spinner').find('input').val().trim(),
    newVal = 0;

  if (btn.attr('data-dir') == 'up') {
    newVal = parseInt(oldValue) + 1;
  } else {
    if (oldValue > 1) {
      newVal = parseInt(oldValue) - 1;
    } else {
      newVal = 1;
    }
  }
  btn.closest('.number-spinner').find('input').val(newVal);
});
//#endregion

//#region toggle fatturazione
$(".check-fatturazione").on("click", function () {
  if ($(this).prop("checked")) {
    $(this)
      .parents(".form")
      .find(".fatturazione-diversa")
      .fadeIn("slow");
  } else $(this).parents(".form").find(".fatturazione-diversa").fadeOut("slow");
});
//#endregion

//#region show password
$(".show-pw-group a").on('click', function (event) {
  event.preventDefault();
  if ($('.show-pw-group input').attr("type") == "text") {
    $('.show-pw-group input').attr('type', 'password');
    $('.show-pw-group i').addClass("fa-eye");
    $('.show-pw-group i').removeClass("fa-eye-slash");
  } else if ($('.show-pw-group input').attr("type") == "password") {
    $('.show-pw-group input').attr('type', 'text');
    $('.show-pw-group i').removeClass("fa-eye");
    $('.show-pw-group i').addClass("fa-eye-slash");
  }
});
//#endregion
//#region mobile cart button
var toppag = $("#cart-mobile");
var pag = $("#stop-cart-mobile");

toppag.css({ position: "fixed", bottom: "0px" });

$(window).on("scroll", function () {
  var scroll = $(this).scrollTop() + $(this).height();
  pag.each(function (i, n) {
    if (scroll > $(this).offset().top) {
      toppag.eq(i).css({ position: "relative" });
      toppag.eq(i).addClass("cart-mobile--static");
      if (i > 0) {
        toppag.eq(i).css({ top: i * -50 });
      }
    }
    if (scroll < $(this).offset().top + toppag.eq(i).height()) {
      toppag.eq(i).css({ position: "fixed", top: "" });
      toppag.eq(i).removeClass("cart-mobile--static");
    }
  });
});

//#endregion

//#region light gallery
$(".gallery__lightgallery__img ").each(function () {
            var src = $(this).attr("src");
            $(this).parent("a").attr("href", src);
        });
if ($(".gallery__lightgallery").length) {
  let lg = document.getElementById("js-lightgallery");
  lg.addEventListener("lgAfterOpen", () => {
    let scrollableLg = document.querySelector(".lg-container");
    /* disableBodyScroll(scrollableLg); */
  });
  lg.addEventListener("lgAfterClose", () => {
    let scrollableLg = document.querySelector(".lg-container");
    /*  enableBodyScroll(scrollableLg); */
  });
  lightGallery(lg, {
    selector: ".gallery__lightgallery",
    mobileSettings: {
      controls: true,
      showCloseIcon: true,
    },
  });
}
//#endregion

